.consulta_estancias {
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(229, 229, 229);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(229, 229, 229);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(229, 229, 229);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(229, 229, 229);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: block;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    line-height: 24px;
    padding: 16px;
    tab-size: 4;
    margin-top: 20px;
}

.consulta_estancias:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
}

.consulta_cliente_datos {
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(229, 229, 229);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(229, 229, 229);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(229, 229, 229);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(229, 229, 229);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: block;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    line-height: 24px;
    padding: 16px;
    tab-size: 4;
    margin-top: 20px;
    margin-bottom: 20px;
}

.consulta_cliente_datos:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
}