@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
    font-size: 80px;
    margin-bottom: 20px;
}

p {
    text-align: justify;
}