.bloque_estancia {
    display: flex;
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(229, 229, 229);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(229, 229, 229);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(229, 229, 229);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(229, 229, 229);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
    box-sizing: border-box;
    display: block;
    font-feature-settings: normal;
    font-variation-settings: normal;
    line-height: 24px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    margin-top: 15px;
    tab-size: 4;
    width: 100%;
}

.estancia_datos {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Crea 3 columnas de igual tamaño */
    gap: 8px; /* Espacio entre elementos */
}

.precio {
    text-align: right;
}

.formulario1 {
    display: flex;
    justify-content: center;
}

#precio_total {
    padding-top: 20px;
    text-align: right;
    font-size: large;
}

#precio_total .fuentepreciototal {
    font-size: x-large;
}

.numhab_num  {
    width: 55px;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    border: solid 1px black;
}

h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.numhab_btn {
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #247848;
    border-radius: 5px;
    color: white;
}

.btn_siguiente {
    background-color: #247848;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

.btn_siguiente:hover {
    transform: scale(1.05);
}

#paso2 {
    width: 1200px;
}

.formulario2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Crea 3 columnas de igual tamaño */
    gap: 10px; /* Espacio entre elementos */
}   

.dato_personal {
    display: flex;
    align-items: center;
    gap: 5px; 
    margin-top: 10px;
}

.dato_personal input {
    height: 40px;
    width: 100%;
    padding: 20px;
    padding-left: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.dato_personal label {
    height: 40px;
    width: 300px;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
}

.tarjeta_credito {
    padding-top: 10px;
}

.tarjetainline {
    display: flex;
    gap: 10px;
}

.tarjetainline input {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    padding: 20px;
    padding-top: 5px;
    padding-right: 2px;
    padding-bottom: 5px;
    border-radius: 5px;
}


.dato_targeta label {
    height: 40px;
    width: 100%;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #247848;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .bloque_estancia {
        width: 100%;
    }

    .estancia_datos {
        display: block;
    }

    .sel_texto {
        padding-top: 15px;
    }

    .sel_precio {
        padding-top: 15px;
    }


    .formulario2 {
        grid-template-columns: repeat(1, 1fr); /* Crea 3 columnas de igual tamaño */
    } 
    
    .dato_personal {
        flex-direction: column;
    }

    .dato_personal label {
        width: 100%;
    }

}

.confirmar_dato {
    display: flex; 
    width: 100%;
    justify-content: space-between;
}